import i18n from '@/i18n';
import moment from 'moment/moment';

const fields = {
  usernameFieldId: 1,
  passwordFieldId: 2,

  MIN_LENGTH_PROP: 'minLength',
  MAX_LENGTH_PROP: 'maxLength',
  MIN_VALUE_PROP: 'minValue',
  MAX_VALUE_PROP: 'maxValue',

  validation: {
    minLength: (fieldName, length) => {
      return i18n.global.t('validation_min_length', {
        fieldName: fieldName,
        length: length
      });
    },
    maxLength: (fieldName, length) => {
      return i18n.global.t('validation_max_length', {
        fieldName: fieldName,
        length: length
      });
    },
    minValue: (fieldName, value) => {
      return i18n.global.t('validation_min_value', {
        fieldName: fieldName,
        length: length
      });
    },
    MAX_VALUE: (fieldName, value) => {
      return i18n.global.t('validation_max_value', {
        fieldName: fieldName,
        length: length
      });
    },
    required: (fieldName) => {
      return i18n.global.t('validation_required', {
        fieldName: fieldName
      });
    }
  },
  defaultValue: {
    DATE_NOW: (format) => {
      return moment().format(format);
    },
    DATE_FIRST_DAY_OF_MONTH: (format) => {
      return moment().startOf('month').format(format);
    },
    DATE_LAST_DAY_OF_MONTH: (format) => {
      return moment().endOf('month').format(format);
    },
    DATE_FIRST_DAY_OF_YEAR: (format) => {
      return moment().startOf('year').format(format);
    }
  }
};

export default fields;
