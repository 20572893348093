import constants from '@/constants';
import router from '@/router';
import storageService from './storage.service';
import store from '@/store';
import userService from '@/services/user.service';

class HelpersService {
  login (orgCode, tokenData, redirectUrl) {
    storageService.setToken(tokenData);
    storageService.setOrgCode(orgCode);

    router.push(redirectUrl);
  }

  logout () {
    const orgCode = storageService.getOrgCode();
    storageService.clearToken();
    store.dispatch('settings/reset');

    router.push(`/login/${orgCode}`);
  }

  showAxiosError (axiosError) {
    if (axiosError.code === constants.error.AXIOS_GENERIC_ERROR &&
      axiosError.response.status === 0 &&
      axiosError.response.readyState === 4) {
      const errorObj = constants.error[constants.error.AXIOS_GENERIC_ERROR];

      const internetConnectionStatus = navigator.onLine ? '' : '<br><br><span class="badge badge-danger me-1">No internet connection detected</span>';

      /* eslint-disable no-new */
      new window.Swal({
        icon: 'error',
        title: errorObj.title,
        html: errorObj.text + internetConnectionStatus,
        padding: '2em'
      });
    } else {
      const errorResponse = axiosError.response;

      let errorObj;

      if (errorResponse?.code === 429) {
        errorObj = constants.error.RATE_LIMITER_ERROR;
      } else {
        errorObj = constants.error[errorResponse.data.errorCode];
        if (!errorObj) {
          errorObj = constants.error.DEFAULT;
        }
      }

      /* eslint-disable no-new */
      new window.Swal({
        icon: 'error',
        title: errorObj.title,
        html: errorObj.text,
        padding: '2em'
      });
    }
  }

  showNotification (type, title) {
    window.Toast.fire({
      icon: type,
      title: title,
      padding: '2em'
    });
  }

  showSuccessSwal (title, text, html) {
    /* eslint-disable no-new */
    new window.Swal({
      icon: 'success',
      title: title,
      text: text,
      html: html,
      padding: '2em'
    });
  }


  sendMsgSwal (title, text, html) {
    /* eslint-disable no-new */

      new window.Swal({
            title: title,
            input: "textarea",
            inputAttributes: {
                autocapitalize: "off",
                rows:8
            },
            showCancelButton: true,
            confirmButtonText: "Send",
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            preConfirm: async (login) => {
                console.log(login)
                const data = await userService.getGroupType()
                // try {
                //     const githubUrl = `https://api.github.com/users/${login}`;
                //     const response = await fetch(githubUrl);
                //     if (!response.ok) {
                //         return Swal.showValidationMessage(`Sending msg is not available in the moment`);
                //     }
                //     return response.json();
                // } catch (error) {
                //     Swal.showValidationMessage(`Sending msg is not available in the moment`);
                // }
            },
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: `${result.value.login}'s avatar`,
                    imageUrl: result.value.avatar_url
                });
            }
        });
  }

  showSwal (config) {
    /* eslint-disable no-new */
    return new window.Swal(config);
  }
};

export default new HelpersService();
