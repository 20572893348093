// initial state
const state = () => ({
  org: null
});

// getters
const getters = {
  getOrg: (state, getters, rootState) => {
    return state.org;
  }
};

// actions
const actions = {
  setOrg ({ commit, state }, org) {
    commit('setOrg', org);
  }
};

// mutations
const mutations = {
  setOrg (state, payload) {
    state.org = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
