import { createStore } from 'vuex';
import error from './modules/error';
import settings from './modules/settings';
import login from './modules/login';

// const debug = process.env.NODE_ENV !== 'production';

export default createStore({
  modules: {
    error,
    settings,
    login
  }
});
