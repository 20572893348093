import axiosService from '@/main';

class UserService {
  async getAdmins (data) {
    const url = process.env.VUE_APP_CLIENT_API_URL;

    if (!data) {
      data = {
        fromDate: null,
        toDate: null,
        username: null,
        email: null,
        mobileNumber: null
      };
    }

    return await axiosService.getAxiosAuthMandatory().get(`${url}/adminuser/admin`, { params: data });
  }

  async getAdmin (userId) {
    const url = process.env.VUE_APP_CLIENT_API_URL;

    return await axiosService.getAxiosAuthMandatory().get(`${url}/adminuser/admin/${userId}`);
  }

  async createAdmin (data) {
    const url = process.env.VUE_APP_CLIENT_API_URL;

    return await axiosService.getAxiosAuthMandatory().post(`${url}/adminuser/admin`, data);
  }

  async updateAdmin (userId, data) {
    const url = process.env.VUE_APP_CLIENT_API_URL;

    await axiosService.getAxiosAuthMandatory().put(`${url}/adminuser/admin/${userId}`, data);
  }

  async getAdminLogs (userId, data) {
    const url = process.env.VUE_APP_CLIENT_API_URL;

    return await axiosService.getAxiosAuthMandatory().get(`${url}/adminuser/admin/${userId}/log`, { params: data });
  }

  async getAdminStatusChangeHistory (userId, data) {
    const url = process.env.VUE_APP_CLIENT_API_URL;

    return await axiosService.getAxiosAuthMandatory().get(`${url}/adminuser/admin/${userId}/status`, { params: data });
  }

  async changeAdminStatus (userId, data) {
    const url = process.env.VUE_APP_CLIENT_API_URL;

    await axiosService.getAxiosAuthMandatory().post(`${url}/adminuser/admin/${userId}/status`, data);
  }

  async resetAdminPassword (userId) {
    const url = process.env.VUE_APP_CLIENT_API_URL;

    return await axiosService.getAxiosAuthMandatory().post(`${url}/adminuser/admin/${userId}/resetpassword`);
  }

  async getUsers (data) {
    const url = process.env.VUE_APP_CLIENT_API_URL;

    if (!data) {
      data = {
        fromDate: null,
        toDate: null,
        username: null,
        email: null,
        mobileNumber: null
      };
    }

    return await axiosService.getAxiosAuthMandatory().get(`${url}/adminuser/user`, { params: data });
  }

  async getUser (userId) {
    const url = process.env.VUE_APP_CLIENT_API_URL;

    return await axiosService.getAxiosAuthMandatory().get(`${url}/adminuser/user/${userId}`);
  }

  async createUser (data) {
    const url = process.env.VUE_APP_CLIENT_API_URL;

    return await axiosService.getAxiosAuthMandatory().post(`${url}/adminuser/user`, data);
  }

  async updateUser (userId, data) {
    const url = process.env.VUE_APP_CLIENT_API_URL;

    await axiosService.getAxiosAuthMandatory().put(`${url}/adminuser/user/${userId}`, data);
  }

  async getUserLogs (userId, data) {
    const url = process.env.VUE_APP_CLIENT_API_URL;

    return await axiosService.getAxiosAuthMandatory().get(`${url}/adminuser/user/${userId}/log`, { params: data });
  }

  async getUserStatusChangeHistory (userId, data) {
    const url = process.env.VUE_APP_CLIENT_API_URL;

    return await axiosService.getAxiosAuthMandatory().get(`${url}/adminuser/user/${userId}/status`, { params: data });
  }

  async changeUserStatus (userId, data) {
    const url = process.env.VUE_APP_CLIENT_API_URL;

    await axiosService.getAxiosAuthMandatory().post(`${url}/adminuser/user/${userId}/status`, data);
  }

  async getUserTransactions (userId, data) {
    const url = process.env.VUE_APP_CLIENT_API_URL;

    return await axiosService.getAxiosAuthMandatory().get(`${url}/adminuser/user/${userId}/transaction`, { params: data });
  }

  async resetUserPassword (userId) {
    const url = process.env.VUE_APP_CLIENT_API_URL;

    return await axiosService.getAxiosAuthMandatory().post(`${url}/adminuser/user/${userId}/resetpassword`);
  }

  async changePassword (data) {
    const url = process.env.VUE_APP_CLIENT_API_URL;

    await axiosService.getAxiosAuthMandatory().post(`${url}/user/changepassword`, data);
  }

  async changeLanguage (langId) {
    const url = process.env.VUE_APP_CLIENT_API_URL;

    await axiosService.getAxiosAuthMandatory().post(`${url}/user/language/${langId}`);
  }

  async getGroupType () {
    const url = process.env.VUE_APP_WEB_NOTIFICATION_API_URL;

    await axiosService.getAxiosAuthMandatory().get(`${url}/chat/grouptype`);
  }

};

export default new UserService();
