// initial state
const state = () => ({
  products: [],
  userStatuses: [],
  verificationTypes: [],
  adminRoles: [],
  userRoles: [],
  languages: [],
  accounts: [],
  transactionTypes: [],
  tablesConfiguration: null,
  fields: [],
  sports: [],
  allUsers: [],
  admins: [],
  users: [],
  permissions: [],
  currencies: [],
  apiInformation: null,
  integrations: [],
  defaultCurrency: null
});

// getters
const getters = {
  getProducts: (state, getters, rootState) => {
    return state.products;
  },
  getUserStatuses: (state, getters, rootState) => {
    return state.userStatuses;
  },
  getVerificationTypes: (state, getters, rootState) => {
    return state.verificationTypes;
  },
  getAdminRoles: (state, getters, rootState) => {
    return state.adminRoles;
  },
  getUserRoles: (state, getters, rootState) => {
    return state.userRoles;
  },
  getLanguages: (state, getters, rootState) => {
    return state.languages;
  },
  getLanguage: (state, getters, rootState) => (languageId) => {
    return state.languages.find(x => x.langId === languageId);
  },
  getAccounts: (state, getters, rootState) => {
    return state.accounts;
  },
  getTransactionTypes: (state, getters, rootState) => {
    return state.transactionTypes;
  },
  getTablesConfiguration: (state, getters, rootState) => {
    return state.tablesConfiguration;
  },
  getTableConfiguration: (state, getters, rootState) => (tableId) => {
    if (!state.tablesConfiguration) {
      return null;
    }

    if (Object.prototype.hasOwnProperty.call(state.tablesConfiguration, tableId)) {
      return state.tablesConfiguration[tableId];
    }

    return null;
  },
  getFields: (state, getters, rootState) => {
    return state.fields;
  },
  getSports: (state, getters, rootState) => {
    return state.sports;
  },
  getAllUsers: (state, getters, rootState) => {
    return state.allUsers;
  },
  getAdmins: (state, getters, rootState) => {
    return state.admins;
  },
  getUsers: (state, getters, rootState) => {
    return state.users;
  },
  getPermissions: (state, getters, rootState) => {
    return state.permissions;
  },
  getCurrencies: (state, getters, rootState) => {
    return state.currencies;
  },
  getApiInformation: (state, getters, rootState) => {
    return state.apiInformation;
  },
  getIntegrations: (state, getters, rootState) => {
    return state.integrations;
  },
  getDefaultCurrency: (state, getters, rootState) => {
    return state.defaultCurrency;
  }
};

// actions
const actions = {
  setProducts ({ commit, state }, products) {
    commit('setProducts', products);
  },
  setUserStatuses ({ commit, state }, userStatuses) {
    commit('setUserStatuses', userStatuses);
  },
  setVerificationTypes ({ commit, state }, verificationTypes) {
    commit('setVerificationTypes', verificationTypes);
  },
  setAdminRoles ({ commit, state }, adminRoles) {
    commit('setAdminRoles', adminRoles);
  },
  setUserRoles ({ commit, state }, userRoles) {
    commit('setUserRoles', userRoles);
  },
  setLanguages ({ commit, state }, languages) {
    commit('setLanguages', languages);
  },
  setAccounts ({ commit, state }, accounts) {
    commit('setAccounts', accounts);
  },
  setTransactionTypes ({ commit, state }, transactionTypes) {
    commit('setTransactionTypes', transactionTypes);
  },
  setTablesConfiguration ({ commit, state }, tablesConfiguration) {
    commit('setTablesConfiguration', tablesConfiguration);
  },
  setFields ({ commit, state }, fields) {
    commit('setFields', fields);
  },
  setSports ({ commit, state }, sports) {
    commit('setSports', sports);
  },
  setAllUsers ({ commit, state }, allUsers) {
    commit('setAllUsers', allUsers);
  },
  setAdmins ({ commit, state }, admins) {
    commit('setAdmins', admins);
  },
  setUsers ({ commit, state }, users) {
    commit('setUsers', users);
  },
  setPermissions ({ commit, state }, permissions) {
    commit('setPermissions', permissions);
  },
  setCurrencies ({ commit, state }, currencies) {
    commit('setCurrencies', currencies);
  },
  setApiInformation ({ commit, state }, apiInformation) {
    commit('setApiInformation', apiInformation);
  },
  setIntegrations ({ commit, state }, integrations) {
    commit('setIntegrations', integrations);
  },
  setDefaultCurrency ({ commit, state }, defaultCurrency) {
    commit('setDefaultCurrency', defaultCurrency);
  },
  reset ({ commit, state }) {
    commit('reset');
  }
};

// mutations
const mutations = {
  setProducts (state, payload) {
    state.products = payload;
  },
  setUserStatuses (state, payload) {
    state.userStatuses = payload;
  },
  setVerificationTypes (state, payload) {
    state.verificationTypes = payload;
  },
  setAdminRoles (state, payload) {
    state.adminRoles = payload;
  },
  setUserRoles (state, payload) {
    state.userRoles = payload;
  },
  setLanguages (state, payload) {
    state.languages = payload;
  },
  setAccounts (state, payload) {
    state.accounts = payload;
  },
  setTransactionTypes (state, payload) {
    state.transactionTypes = payload;
  },
  setTablesConfiguration (state, payload) {
    state.tablesConfiguration = payload;
  },
  setFields (state, payload) {
    state.fields = payload;
  },
  setSports (state, payload) {
    state.sports = payload;
  },
  setAllUsers (state, payload) {
    state.allUsers = payload;
  },
  setAdmins (state, payload) {
    state.admins = payload;
  },
  setUsers (state, payload) {
    state.users = payload;
  },
  setPermissions (state, payload) {
    state.permissions = payload;
  },
  setCurrencies (state, payload) {
    state.currencies = payload;
  },
  setApiInformation (state, payload) {
    state.apiInformation = payload;
  },
  setIntegrations (state, payload) {
    state.integrations = payload;
  },
  setDefaultCurrency (state, payload) {
    state.defaultCurrency = payload;
  },
  reset (state) {
    state.products = [];
    state.adminRoles = [];
    state.userRoles = [];
    state.languages = [];
    state.accounts = [];
    state.transactionTypes = [];
    state.fields = [];
    state.sports = [];
    state.allUsers = [];
    state.admins = [];
    state.users = [];
    state.permissions = [];
    state.tablesConfiguration = null;
    state.currencies = [];
    state.apiInformation = null;
    state.integrations = [];
    state.defaultCurrency = null;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
