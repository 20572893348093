import { createRouter, createWebHashHistory } from 'vue-router';
import storageServices from '@/services/storage.service';
import store from '@/store';
import i18n from '@/i18n';

const routes = [
  {
    path: '/',
    redirect: '/dashboard/overview',
    name: 'home'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/LoginView.vue'),
    meta: {
      title: i18n.global.t('log_in')
    }
  },
  {
    path: '/login/:orgCode',
    name: 'loginWithOrganizationCode',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/LoginView.vue'),
    meta: {
      title: i18n.global.t('log_in')
    }
  },
  {
    path: '/login/forgot',
    name: 'forgot',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/ForgotPasswordView.vue'),
    meta: {
      title: i18n.global.t('password_recovery')
    }
  },
  {
    path: '/login/forgot/:orgCode',
    name: 'forgotWithOrganizationCode',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/ForgotPasswordView.vue'),
    meta: {
      title: i18n.global.t('password_recovery')
    }
  },
  {
    path: '/login/resetpassword/:orgCode/:token',
    name: 'resetPassword',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/ResetPasswordView.vue'),
    meta: {
      title: i18n.global.t('reset_password')
    }
  },
  {
    path: '/error',
    name: 'error',
    component: () => import(/* webpackChunkName: "error" */ '../views/error/ErrorView.vue'),
    meta: {
      title: i18n.global.t('error')
    }
  },

  // Dashboard
  {
    path: '/dashboard/overview',
    name: 'dashboardOverview',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard/OverviewView.vue'),
    meta: {
      title: i18n.global.t('dashboard_overview')
    }
  },

  // Admins
  {
    path: '/admins/create',
    name: 'createAdmin',
    component: () => import(/* webpackChunkName: "admins" */ '../views/admins/CreateAdminView.vue'),
    meta: {
      title: i18n.global.t('create_admin')
    }
  },
  {
    path: '/admins',
    name: 'listAdmins',
    component: () => import(/* webpackChunkName: "admins" */ '../views/admins/AdminsView.vue'),
    meta: {
      title: i18n.global.t('admins')
    }
  },
  {
    path: '/admins/details',
    children: [
      {
        path: '',
        name: 'adminDetails',
        component: () => import(/* webpackChunkName: "admins" */ '../views/admins/AdminDetailsView.vue'),
        meta: {
          title: i18n.global.t('admins')
        }
      },
      {
        path: ':id(\\d+)',
        name: 'adminDetailsWithId',
        props: true,
        component: () => import(/* webpackChunkName: "admins" */ '../views/admins/AdminDetailsView.vue'),
        meta: {
          title: i18n.global.t('admin_details')
        }
      }
    ]
  },

  // Users
  {
    path: '/users/create',
    name: 'createUser',
    component: () => import(/* webpackChunkName: "user" */ '../views/users/CreateUserView.vue'),
    meta: {
      title: i18n.global.t('create_user')
    }
  },
  {
    path: '/users',
    name: 'listUsers',
    component: () => import(/* webpackChunkName: "user" */ '../views/users/UsersView.vue'),
    meta: {
      title: i18n.global.t('users')
    }
  },
  {
    path: '/users/details',
    children: [
      {
        path: '',
        name: 'userDetails',
        component: () => import(/* webpackChunkName: "user" */ '../views/users/UserDetailsView.vue'),
        meta: {
          title: i18n.global.t('user_details')
        }
      },
      {
        path: ':id(\\d+)',
        name: 'userDetailsWithId',
        props: true,
        component: () => import(/* webpackChunkName: "user" */ '../views/users/UserDetailsView.vue'),
        meta: {
          title: i18n.global.t('user_details')
        }
      }
    ]
  },

  // Transactions
  {
    path: '/transactions',
    name: 'listTransactions',
    component: () => import(/* webpackChunkName: "transaction" */ '../views/transactions/TransactionsView.vue'),
    meta: {
      title: i18n.global.t('transactions')
    }
  },

  // Reports
  {
    path: '/reports/user-report',
    name: 'userReports',
    component: () => import(/* webpackChunkName: "report" */ '../views/reports/UserReportView.vue'),
    meta: {
      title: i18n.global.t('user_report')
    }
  },
  {
    path: '/reports/commissions',
    name: 'commissions',
    component: () => import(/* webpackChunkName: "report" */ '../views/reports/CommisionsView.vue'),
    meta: {
      title: i18n.global.t('commissions')
    }
  },
  {
    path: '/reports/product-report',
    name: 'productReport',
    component: () => import(/* webpackChunkName: "report" */ '../views/reports/ProductReportView.vue'),
    meta: {
      title: i18n.global.t('product_report')
    }
  },
  {
    path: '/reports/product-report-by-day',
    name: 'productReportByDay',
    component: () => import(/* webpackChunkName: "report" */ '../views/reports/ProductReportByDayView.vue'),
    meta: {
      title: i18n.global.t('product_report_by_day')
    }
  },

  // Configuration
  {
    path: '/configuration/roles',
    name: 'roles',
    component: () => import(/* webpackChunkName: "config" */ '../views/configuration/RolesView.vue'),
    meta: {
      title: i18n.global.t('roles')
    }
  },
  {
    path: '/configuration/user-fields',
    name: 'userFields',
    component: () => import(/* webpackChunkName: "config" */ '../views/configuration/UserFieldsView.vue'),
    meta: {
      title: 'User Fields'
    }
  },
  {
    path: '/configuration/accounts',
    name: 'accounts',
    component: () => import(/* webpackChunkName: "config" */ '../views/configuration/AccountsView.vue'),
    meta: {
      title: i18n.global.t('accounts')
    }
  },
  {
    path: '/configuration/white-labeling',
    name: 'whiteLabeling',
    component: () => import(/* webpackChunkName: "config" */ '../views/configuration/WhiteLabeling.vue'),
    meta: {
      title: i18n.global.t('accounts')
    }
  },
  {
    path: '/configuration/integration/:id(\\d+)',
    name: 'integrationConfig',
    props: true,
    component: () => import(/* webpackChunkName: "config" */ '../views/configuration/IntegrationView.vue'),
    meta: {
      title: 'Integration'
    }
  },

  // Analytics
  {
    path: '/analytics/tipsearch/:groupBy(\\d+)',
    name: 'tipSearchAnalytic',
    props: true,
    component: () => import(/* webpackChunkName: "user-config" */ '../views/analytics/TipSearchAnalyticView.vue')
  },

  // Documentation
  {
    path: '/documentation/gaming-platform',
    name: 'gamingPlatform',
    component: () => import(/* webpackChunkName: "documentation" */ '../views/documentation/GamingPlatformView.vue'),
    meta: {
      title: i18n.global.t('gaming_platform')
    }
  },
  {
    path: '/documentation/bets4us',
    name: 'bets4usDocumentation',
    component: () => import(/* webpackChunkName: "documentation" */ '../views/documentation/Bets4UsView.vue'),
    meta: {
      title: i18n.global.t('Bets4Us')
    }
  },

  // User settings
  {
    path: '/user-settings/change-password',
    name: 'changePassword',
    component: () => import(/* webpackChunkName: "user-settings" */ '../views/user-settings/ChangePasswordView.vue'),
    meta: {
      title: i18n.global.t('change_password')
    }
  },

  // route if not exists
  {
    path: '/:pathMatch(.*)',
    name: 'pathNotFound',
    component: () => import(/* webpackChunkName: "error" */ '../views/error/ErrorView.vue')
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;

router.beforeEach((to, from) => {
  if (!to.path.startsWith('/login') && !storageServices.isAuth()) {
    const orgCode = storageServices.getOrgCode() || '';

    storageServices.clearToken();

    return {
      path: `/login/${orgCode}`,
      query: { redirect: to.fullPath }
    };
  }

  if (to.path.startsWith('/login') && storageServices.isAuth()) {
    return {
      name: 'dashboardOverview',
      replace: true
    };
  }

  if (to.path.startsWith('/analytics/tipsearch') && storageServices.isAuth()) {
    const groupBy = +to.path.split('/')[3];
    if (isNaN(groupBy) || groupBy < 0 || groupBy > 7) {
      store.dispatch('error/setError', {
        errorCode: 404,
        title: i18n.global.t('page_not_found'),
        text: ''
      });

      return { name: 'error' };
    }
  }

  if (!to.path.startsWith('/user-settings') && !to.path.startsWith('/error') && storageServices.isAuth()) {
    const permission = to.path.split('/')[1].replaceAll('-', '');

    if (!storageServices.hasPermission(permission)) {
      store.dispatch('error/setError', {
        errorCode: 403,
        title: i18n.global.t('access_denied'),
        text: i18n.global.t('access_denied_text')
      });

      return { name: 'error' };
    }
  }
});

router.afterEach((to, from) => {
  let title = to?.meta?.title || '';
  if (to.name === 'tipSearchAnalytic') {
    title = 'tip_search_by_';
    switch (+to.params.groupBy) {
      case 0:
        title = i18n.global.t(`${title}advice`);
        break;
      case 1:
        title = i18n.global.t(`${title}advisor`);
        break;
      case 2:
        title = i18n.global.t(`${title}sport`);
        break;
      case 3:
        title = i18n.global.t(`${title}league`);
        break;
      case 4:
        title = i18n.global.t(`${title}user`);
        break;
      case 5:
        title = i18n.global.t(`${title}market`);
        break;
      case 6:
        title = i18n.global.t(`${title}event`);
        break;
      case 7:
        title = i18n.global.t(`${title}product`);
        break;
    }
  }
  document.title = `${title} | ${i18n.global.t('app_title')}`;
});
