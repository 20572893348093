import constants from '@/constants';

const TOKEN_DATA = 'bets4us-client-token';
const ORG_CODE_DATA = 'bets4us-client-org-code';
const DEVICE_ID_DATA = 'bets4us-client-device-id';

class StorageService {
  getToken () {
    return JSON.parse(window.localStorage.getItem(TOKEN_DATA));
  }

  setToken (tokenData) {
    window.localStorage.setItem(TOKEN_DATA, JSON.stringify(tokenData));
    this.setDeviceId(tokenData.login.user.deviceId);
  }

  getAccessToken () {
    const tokenData = this.getToken();

    if (!tokenData) {
      return null;
    }

    return tokenData.login.token;
  }

  getRefreshToken () {
    const tokenData = this.getToken();
    if (!tokenData) {
      return null;
    }

    return tokenData.login.refreshToken;
  }

  isExpiredToken () {
    const tokenData = this.getToken();
    if (!tokenData) {
      return true;
    }

    const date = new Date();

    return date >= new Date(tokenData.login.user.tokenTime);
  }

  isExpiredRefreshToken () {
    const tokenData = this.getToken();
    if (!tokenData) {
      return true;
    }

    const date = new Date();

    return date >= new Date(tokenData.login.user.refreshTokenTime);
  }

  getUserProfile () {
    const tokenData = this.getToken();
    if (!tokenData) {
      return null;
    }

    return tokenData.userProfile;
  }

  getLanguage () {
    const tokenData = this.getToken();
    if (!tokenData) {
      return null;
    }

    return tokenData.userProfile.lang;
  }

  setLanguage (lang) {
    const tokenData = this.getToken();
    if (tokenData) {
      tokenData.userProfile.lang = lang;
      this.setToken(tokenData);
    }
  }

  getLanguageDateFormat () {
    const tokenData = this.getToken();
    if (!tokenData) {
      return null;
    }

    return tokenData.userProfile.lang.dateFormat;
  }

  getLanguageSign () {
    const tokenData = this.getToken();
    if (!tokenData) {
      return null;
    }

    return tokenData.userProfile.lang.langSign;
  }

  getLanguageId () {
    const tokenData = this.getToken();
    if (!tokenData) {
      return null;
    }

    return tokenData.userProfile.lang.langId;
  }

  clearToken () {
    window.localStorage.removeItem(TOKEN_DATA);
    window.localStorage.removeItem(ORG_CODE_DATA);
  }

  isAuth () {
    return !this.isExpiredToken() || !this.isExpiredRefreshToken();
  }

  getLoggedInUser () {
    const tokenData = this.getToken();
    if (tokenData && this.isAuth()) {
      return tokenData.login.user;
    }

    return null;
  }

  getOrgCode () {
    return window.localStorage.getItem(ORG_CODE_DATA);
  }

  setOrgCode (orgCode) {
    window.localStorage.setItem(ORG_CODE_DATA, orgCode);
  }

  getDeviceId () {
    return window.localStorage.getItem(DEVICE_ID_DATA);
  }

  setDeviceId (deviceId) {
    const oldDeviceId = this.getDeviceId();
    if (!oldDeviceId) {
      window.localStorage.setItem(DEVICE_ID_DATA, deviceId);
    }
  }

  hasPermission (permission) {
    const tokenData = this.getToken();
    if (tokenData) {
      return tokenData.login.user.permissions.some(x => x.toLowerCase() === `${constants.permissionPrefix}${permission}`);
    }

    return false;
  }
}

export default new StorageService();
