<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App',
  data () {
    return {
      refreshingToken: null
    };
  }
};

</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

</style>
