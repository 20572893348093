const customTable = {
  tableEvents: {
    CHANGED_LIMIT: 'vue-tables.limit'
  },
  events: {
    CLICK_BTN_VIEW: 'clickBtnView.vuetable',
    CLICK_BTN_SEND_MSG: 'clickBtnSendMsg.vuetable',
    CLICK_BTN_EDIT: 'clickBtnEdit.vuetable',
    CLICK_BTN_DELETE: 'clickBtnDelete.vuetable',
    CLICK_BTN_BLOCK_USER: 'clickBtnBlockUser.vuetable',
    CLICK_BTN_UNBLOCK_USER: 'clickBtnUnblockUser.vuetable',
    CLICK_BTN_RESET_USER_PASSWORD: 'clickBtnResetUserPassword.vuetable',
    SET_DATA: 'setData.vuetable',
    UPDATED_ROW: 'updatedRow.vuetable'
  },
  columnType: {
    AVATAR: 0,
    BADGE: 1,
    BOOL: 2,
    ACTION: 3,
    NUMBER: 4,
    MONEY: 5,
    SINGLE_ACTION: 6,
  },
  tablesConfigurationPropertyName: 'TABLES_CONFIGURATION'
};

export default customTable;
