import i18n from '@/i18n';

const enums = {
  userStatus: {
    registrationError: -1,
    pending: 0,
    active: 1,
    blocked: 2,
    requestedDeleted: 3,
    deleted: 4
  },
  userStatusForTable: {
    '-1': {
      title: i18n.global.t('enum.user_status.RegistrationError'),
      badgeClass: 'badge-danger'
    },
    0: {
      title: i18n.global.t('enum.user_status.Pending'),
      badgeClass: 'badge-primary'
    },
    1: {
      title: i18n.global.t('enum.user_status.Active'),
      badgeClass: 'badge-success'
    },
    2: {
      title: i18n.global.t('enum.user_status.Blocked'),
      badgeClass: 'badge-warning'
    },
    3: {
      title: i18n.global.t('enum.user_status.RequestedDeleted'),
      badgeClass: 'badge-dark'
    },
    4: {
      title: i18n.global.t('enum.user_status.Deleted'),
      badgeClass: 'badge-danger'
    }
  },
  fieldType: {
    text: 0,
    textArea: 1,
    encryptedText: 2,
    encryptedTextArea: 3,
    password: 4,
    email: 5,
    phoneNumber: 6,

    date: 10,
    dateTime: 11,
    time: 12,

    boolean: 13,
    number: 14,
    decimalNumber: 15,

    options: 100,
    multipleOptions: 101
  },
  analytics: {
    tipSearch: {
      tip: 0,
      advisor: 1,
      sport: 2,
      league: 3,
      user: 4,
      market: 5,
      event: 6,
      product: 7
    }
  }
};

export default enums;
