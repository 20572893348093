import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

// datatables
import { ClientTable } from 'v-tables-3';

// json to excel
import vue3JsonExcel from 'vue3-json-excel';

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import storageService from './services/storage.service';
import constants from './constants';
import helpersService from './services/helpers.service';

// vue-i18n
import i18n from './i18n';

import Swal from 'sweetalert2';
window.Swal = Swal;
window.Toast = window.Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timerProgressBar: true,
  timer: 3000,
  padding: '2em',
  showClass: {
    popup: ''
  }
});

let refreshingToken = null;

const axiosNoAuthMandatory = axios.create();

axiosNoAuthMandatory.interceptors.request.use(
  async (config) => {
    const webApiUrl = process.env.VUE_APP_CLIENT_API_URL;
    const webApiKey = process.env.VUE_APP_CLIENT_API_KEY;

    config.headers['X-ApiKey'] = webApiKey;

    const token = storageService.getToken();
    if (!token) {
      return Promise.resolve(config);
    }

    if (storageService.isExpiredToken()) {
      if (storageService.isExpiredRefreshToken()) {
        return Promise.resolve(config);
      }

      try {
        refreshingToken = refreshingToken || refreshToken(webApiUrl, webApiKey);
        const response = await refreshingToken;
        refreshingToken = null;
        if (response.data) {
          storageService.setToken(response.data);
        }
      } catch (error) {
        return Promise.resolve(config);
      }
    }

    config.headers.Authorization = `Bearer ${storageService.getAccessToken()}`;
    return Promise.resolve(config);
  }
);

function refreshToken (webApiUrl, webApiKey) {
  return axios
    .post(`${webApiUrl}/login/refresh`, null, {
      headers: {
        'X-ApiKey': webApiKey,
        Authorization: `Bearer ${storageService.getRefreshToken()}`
      }
    });
}

function isAxiosErrorNoNetworkOrServerUnreachable (axiosError) {
  return axiosError.code === constants.error.AXIOS_GENERIC_ERROR &&
    axiosError.response.status === 0 &&
    axiosError.response.readyState === 4;
}

function logout () {
  const orgCode = storageService.getOrgCode();
  storageService.clearToken();
  router.push(`/login/${orgCode}`);

  return Promise.reject(new Error(constants.error.CUSTOM_USER_NOT_LOGGED_IN));
}

const axiosAuthMandatory = axios.create();

axiosAuthMandatory.interceptors.request.use(
  async (config) => {
    const webApiUrl = process.env.VUE_APP_CLIENT_API_URL;
    const webApiKey = process.env.VUE_APP_CLIENT_API_KEY;

    config.headers['X-ApiKey'] = webApiKey;

    const token = storageService.getToken();
    if (!token) {
      return logout();
    }

    if (storageService.isExpiredToken()) {
      if (storageService.isExpiredRefreshToken()) {
        return logout();
      }

      try {
        refreshingToken = refreshingToken || refreshToken(webApiUrl, webApiKey);
        const response = await refreshingToken;
        refreshingToken = null;
        if (response.data) {
          storageService.setToken(response.data);
        }
      } catch (error) {
        if (!isAxiosErrorNoNetworkOrServerUnreachable(error)) {
          return logout();
        }
      }
    }

    config.headers['X-DeviceId'] = storageService.getDeviceId();
    config.headers.Authorization = `Bearer ${storageService.getAccessToken()}`;
    return Promise.resolve(config);
  }
);

axiosNoAuthMandatory.interceptors.response.use((response) => {
  return response;
}, function (error) {
  store.dispatch('error/setError', error);
  helpersService.showAxiosError(error);
  return Promise.reject(error);
});

axiosAuthMandatory.interceptors.response.use((response) => {
  return response;
}, function (error) {
  store.dispatch('error/setError', error);
  helpersService.showAxiosError(error);
  return Promise.reject(error);
});

const axiosService = {
  getAxiosNoAuthMandatory () {
    return axiosNoAuthMandatory;
  },
  getAxiosAuthMandatory () {
    return axiosAuthMandatory;
  }
};

export default axiosService;

createApp(App).use(router).use(store).use(ClientTable).use(vue3JsonExcel).use(i18n).mount('#app');
