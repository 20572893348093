import i18n from '@/i18n';
import error from './error';
import customTable from './custom-table';
import enums from './enums';
import events from './events';
import fields from './fields';
import charts from './charts';

const constants = {
  defaultLang: 'en',
  permissionPrefix: 'admin',
  loadingButtonComponentEvent: 'customBtnClick',
  loadingButtonComponentClearEvent: 'customBtnClearClick',
  dateFormatText: 'MMM DD, YYYY',
  dateTimeFormatText: `MMM DD, YYYY [${i18n.global.t('date_time_at')}] HH:mm:ss`,
  dateFormatData: 'YYYY-MM-DD',
  dateTimeFormatData: 'YYYY-MM-DD HH:mm:ss',
  timeFormatData: 'HH:mm',
  datePickerDateFormatText: 'M d, Y',
  datePickerDateTimeFormatText: 'M d, Y H:i',
  datePickerDateFormatData: 'Y-m-d',
  datePickerDateTimeFormatData: 'Y-m-d H:i',
  datePickerTimeFormatData: 'H:i',
  moneyDecimals: 2,
  error,
  customTable,
  enums,
  events,
  fields,
  charts
};

export default constants;
