import i18n from '@/i18n';

const error = {
  ERR_NETWORK: {
    title: i18n.global.t('server_unreachable'),
    text: i18n.global.t('try_again_text')
  },
  ORG_CODE_ERROR: {
    title: i18n.global.t('login_failed'),
    text: i18n.global.t('try_again_text')
  },
  USER_PERMISSION_ERROR: {
    title: i18n.global.t('login_failed'),
    text: i18n.global.t('cannot_login_text')
  },
  CREDENTIALS_MISMATCH: {
    title: i18n.global.t('login_failed'),
    text: i18n.global.t('try_again_text')
  },
  USER_NO_PERMISSION: {
    title: i18n.global.t('login_failed'),
    text: i18n.global.t('try_again_text')
  },
  MAXIMUM_ACTIVE_LOGINS_REACHED: {
    title: i18n.global.t('login_failed'),
    text: i18n.global.t('max_active_login_error')
  },
  MAXIMUM_LOGIN_ATTEMPTS: {
    title: i18n.global.t('login_failed'),
    text: i18n.global.t('max_login_attempts_error')
  },
  NOT_FOUND: {
    title: i18n.global.t('method_not_found'),
    text: i18n.global.t('method_not_found_text')
  },
  METHOD_NOT_ALLOWED: {
    title: i18n.global.t('method_not_allowed'),
    text: i18n.global.t('method_not_allowed_text')
  },
  PASSWORD_TOKEN_NOT_VALID: {
    title: i18n.global.t('reset_password_failed'),
    text: i18n.global.t('password_token_not_valid')
  },
  PASSWORD_TOKEN_EXPIRED: {
    title: i18n.global.t('reset_password_failed'),
    text: i18n.global.t('password_token_expired')
  },
  PASSWORD_TOKEN_USED: {
    title: i18n.global.t('reset_password_failed'),
    text: i18n.global.t('password_token_used')
  },
  USER_NOT_EXISTS: {
    title: i18n.global.t('reset_password_failed'),
    text: i18n.global.t('user_not_exists')
  },
  RESET_PASSWORD_USER_NOT_SET: {
    title: i18n.global.t('reset_password_failed'),
    text: i18n.global.t('reset_password_user_not_set')
  },
  SQL_ERROR: {
    title: i18n.global.t('sql_error'),
    text: i18n.global.t('try_again_occur_text')
  },
  ENTITY_NOT_FOUND: {
    title: i18n.global.t('entity_not_found')
  },
  ROLE_NOT_ADMIN_ROLE: {
    title: i18n.global.t('create_admin_failed'),
    text: i18n.global.t('create_admin_need_admin_role')
  },
  ROLE_NOT_USER_ROLE: {
    title: i18n.global.t('create_user_failed'),
    text: i18n.global.t('create_user_need_user_role')
  },
  PARENT_NOT_ADMIN: {
    title: i18n.global.t('create_admin_failed'),
    text: i18n.global.t('create_admin_parent_not_admin')
  },
  PARENT_NOT_USER: {
    title: i18n.global.t('create_user_failed'),
    text: i18n.global.t('create_user_parent_not_user')
  },
  USER_NOT_WITH_ADMIN_ROLE: {
    title: i18n.global.t('admin_not_found'),
    text: i18n.global.t('admin_not_found_text')
  },
  USER_NOT_WITH_USER_ROLE: {
    title: i18n.global.t('user_not_found'),
    text: i18n.global.t('user_not_found_text')
  },
  CREATE_ACCOUNT_CODE_NOT_UNIQUE: {
    title: i18n.global.t('save_account'),
    text: i18n.global.t('create_account_unique_code')
  },
  RATE_LIMITER_ERROR: {
    title: i18n.global.t('too_many_requests')
  },
  DEFAULT: {
    title: i18n.global.t('default_error_title'),
    text: i18n.global.t('default_error_text')
  },
  CUSTOM_USER_NOT_LOGGED_IN: 'USER_NOT_LOGGED_IN',
  AXIOS_GENERIC_ERROR: 'ERR_NETWORK'
};

export default error;
