// initial state
const state = () => ({
  error: null
});

// getters
const getters = {
  getError: (state, getters, rootState) => {
    return state.error;
  }
};

// actions
const actions = {
  setError ({ commit, state }, error) {
    commit('setError', error);
  }
};

// mutations
const mutations = {
  setError (state, payload) {
    state.error = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
